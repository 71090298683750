/**** background *******/
@import url("https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap");

body {
  background-color: #f5f5f5;
  height: 100%;
  position: relative;
}

.spacer {
  height: 120px;
}

.content-wrapper {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 55px;
}

/********* text **********/

.display-4 {
  font-size: 40px;
  text-align: center;
  margin-bottom: 16px;
}

.lead {
  font-weight: 400;
}

.placeholder {
  position: relative;
}

.placeholder::after {
  position: absolute;
  left: 5px;
  top: 14px;
  content: attr(data-placeholder);
  pointer-events: none;
  opacity: 0.7;
}

/* card */

.card-body {
  height: 100%;
  padding: 29px;
}

.card {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin-bottom: 16px;
}

/********* input fields ***********/

/* This is old code for spacing on the mask. */

/* input[type="text"][name$="Phone"] {
  font-family: monospace;
  letter-spacing: 1px;
  font-size: 120%;
} */

/* this code fixes a bootstrap function to not change the borders */

input {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

/* above code fixes a bootstrap function to not change the borders */

div > label {
  margin-bottom: 4px;
}

.placeholder {
  position: relative;
}

.placeholder::after {
  position: absolute;
  left: 5px;
  top: 14px;
  content: attr(data-placeholder);
  pointer-events: none;
  opacity: 0.7;
}

textarea.form-control {
  height: 50px;
}

.form-control {
  border: 2px solid #0b82f1;
  height: 50px;
  padding-left: 15px;
  padding-right: 10px;
}

input[type="checkbox"] {
  transform: scale(1.2);
}

input + label {
  font-weight: 700;
}

button {
  margin: 0 auto;
  display: block;
}

select {
  border: 2px solid #0b82f1;
  border-left: 0px;
  border-radius: 0.25rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  outline: none;
}

select:focus {
  box-shadow: 0px 0px 12px #0b82f1 !important;
  clip-path: inset(-5px -5px -5px 0px);
}

.custom-select {
  border: 2px solid #0b82f1;
  height: 50px;
}

textarea:focus {
  border-color: #0b82f1 !important;
  box-shadow: 0px 0px 12px #0b82f1 !important;
}

.custom-select:focus {
  border-color: #0b82f1 !important;
  box-shadow: 0px 0px 12px #0b82f1 !important;
}

input:focus {
  border-color: #0b82f1 !important;
  box-shadow: 0px 0px 12px #0b82f1 !important;
}

.valid {
  border-color: #5cb85c;
}

.invalid {
  border-color: #d9534f;
}

.lead {
  font-weight: 400;
}

/* media queries */

@media only screen and (max-width: 425px) {
  .form-input > label {
    top: 30px;
    left: 25px;
  }
}

@media only screen and (max-width: 375px) {
  .display-4 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1300px) {
  .grecaptcha-badge {
    display: none !important;
  }
}
